/*
===============
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}

/*
===============
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-black);

}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
===============
Navbar
===============
*/
nav {

  box-shadow: var(--light-shadow);
      margin-top: 3.5rem;
        font-size: 2.35rem;
}

.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

}

.nav-toggle {
  font-size: 1.5rem;
  color: var(--clr-primary-5);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}

.nav-toggle:hover {
  color: var(--clr-primary-1);
  transform: rotate(90deg);
}

.logo1 {
   width: 100%;
   height: 50vh;

}

.links a {
  color: var(--clr-grey-3);
  font-size: 1.00rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  transition: var(--transition);
}

.links a:hover {
  background: var(--clr-primary-8);
  color: var(--clr-primary-5);
  padding-left: 1.5rem;
}

.social-icons {
  display: none;
}

.links-container {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
  z-index: 1;
}

.show-container {
  height: 10rem;
}

@media screen and (min-width: 800px) {
  .nav-center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    width: 100%;

  }

  .nav-header {
    padding: 0;
  }

  .nav-toggle {
    display: none;
  }

  .links-container {
    height: auto !important;
  }

  .links {
    display: flex;
  }

  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }

  .links a:hover {
    padding: 0;
    background: transparent;
  }

  .social-icons {
    display: flex;
  }

  .social-icons a {
    margin: 0 0.5rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }

  .social-icons a:hover {
    color: var(--clr-primary-7);
  }

  .logo1{
    width: 120%;

  }
}

/* BUTTON CREATED BY CHATGPT / CSS IS TAKEN FROM LESSIN-REPO-NEW */
.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-10);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  border-color: transparent;
}

.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
}


/* MODAL STYLES I TOOK FROM LESSIN-REPO-NEW */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  transition: var(--transition);
  visibility: hidden;
  z-index: -1;
}
.chat-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  transition: var(--transition);
  visibility: hidden;
  z-index: -1;
}
 .close-chat-btn {
   position: absolute;
   top: 1rem;
   right: 1rem;
   font-size: 2rem;
   background: transparent;
   border-color: transparent;
   color: var(--clr-red-dark);
   cursor: pointer;
 }

 .show-modal {
   visibility: visible;
   z-index: 10;
 }

 .show-chat {
   visibility: visible;
   z-index: 10;
 }


 @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);

 * {
   font-family: Roboto;
 }

 .footer-distributed {
   background: #444;
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
   box-sizing: border-box;
   width: 100%;
   font: bold 16px sans-serif;
   text-align: left;
   padding: 50px 60px 40px;
   overflow: hidden;

 }

 section {
   width: 100%;
   display: inline-block;
   background: #ccc;
   height: 60vh;
   text-align: center;
   font-size: 22px;
   font-weight: 700;
   text-decoration: underline;
    margin-top: 15rem;
 }


 /* Footer left */

 .footer-distributed .footer-left {
   float: left;
 }

 /* The company logo */

 .footer-distributed h3 {
   color: #ffffff;
   font: normal 75px 'Roboto', cursive;
   margin: 0 0 10px;
 }
 @media (max-width: 768px) {
    .footer-distributed h3 {
        color: #ffffff;
        font: normal 40px 'Roboto', cursive;
        margin: 0 0 10px;
      }
 }

 .footer-distributed h3 span {
   color: #5383d3;
 }

 /* Footer links */

 .footer-distributed .footer-links {
   color: #ffffff;
   margin: 0 0 10px;
   padding: 0;
 }

 .footer-distributed .footer-links a {
   display: inline-block;
   line-height: 1.8;
   text-decoration: none;
   color: inherit;
 }

 .footer-distributed .footer-company-name {
   color: #8f9296;
   font-size: 14px;
   font-weight: normal;
   margin: 0;
 }

 /* Footer social icons */

 .footer-distributed .footer-icons {
   margin-top: 40px;
 }

 .footer-distributed .footer-icons a {
   display: inline-block;
   width: 35px;
   height: 35px;
   cursor: pointer;
   background-color: #33383b;
   border-radius: 2px;

   font-size: 20px;
   color: #ffffff;
   text-align: center;
   line-height: 35px;

   margin-right: 3px;
   margin-bottom: 5px;
 }

 /* Footer Right */

 .footer-distributed .footer-right {
   float: right;
 }

 .footer-distributed .footer-right p {
   display: inline-block;
   vertical-align: top;
   margin: 15px 42px 0 0;
   color: #ffffff;
 }

 /* The contact form */

 .footer-distributed form {
   display: inline-block;
 }

 .footer-distributed form input,
 .footer-distributed form textarea {
   display: block;
   border-radius: 3px;
   box-sizing: border-box;
   background-color: #1f2022;
   box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
   border: none;
   resize: none;

   font: inherit;
   font-size: 14px;
   font-weight: normal;
   color: #d1d2d2;

   width: 400px;
   padding: 18px;
 }

 .footer-distributed ::-webkit-input-placeholder {
   color: #5c666b;
 }

 .footer-distributed ::-moz-placeholder {
   color: #5c666b;
   opacity: 1;
 }

 .footer-distributed :-ms-input-placeholder {
   color: #5c666b;
 }


 .footer-distributed form input {
   height: 55px;
   margin-bottom: 15px;
 }

 .footer-distributed form textarea {
   height: 100px;
   margin-bottom: 20px;
 }

 .footer-distributed form button {
   border-radius: 3px;
   background-color: #33383b;
   color: #ffffff;
   border: 0;
   padding: 15px 50px;
   font-weight: bold;
   float: right;
 }

 /* If you don't want the footer to be responsive, remove these media queries */

 @media (max-width: 1000px) {

   .footer-distributed {
     font: bold 14px sans-serif;
   }

   .footer-distributed .footer-company-name {
     font-size: 12px;
   }

   .footer-distributed form input,
   .footer-distributed form textarea {
     width: 250px;
   }

   .footer-distributed form button {
     padding: 10px 35px;
   }

 }

 @media (max-width: 800px) {

   .footer-distributed {
     padding: 30px;
   }

   .footer-distributed .footer-left,
   .footer-distributed .footer-right {
     float: none;
     max-width: 300px;
     margin: 0 auto;
   }

   .footer-distributed .footer-left {
     margin-bottom: 40px;
   }

   .footer-distributed form {
     margin-top: 30px;
   }

   .footer-distributed form {
     display: block;
   }

   .footer-distributed form button {
     float: none;
   }
 }

.menu {
  padding: 5rem 0;
}
.title {
  text-align: center;
  margin-bottom: 2rem;
}
.underline {
  width: 5rem;
  height: 0.25rem;
  background: var(--clr-gold);
  margin-left: auto;
  margin-right: auto;
}
.btn-container {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}
.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: var(--clr-gold);
  cursor: pointer;
  transition: var(--transition);
  border-radius: var(--radius);
}
.filter-btn:hover {
  background: var(--clr-gold);
  color: var(--clr-white);
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  gap: 3rem 2rem;
  justify-items: center;
}
.menu-item {
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}
.photo {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border: 0.25rem solid var(--clr-gold);
  border-radius: var(--radius);
  display: block;
}
.item-info header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted var(--clr-grey-5);
}
.item-info h4 {
  margin-bottom: 0.5rem;
}
.price {
  color: var(--clr-gold);
}
.item-text {
  margin-bottom: 0;
  padding-top: 1rem;
}

@media screen and (min-width: 768px) {
  .menu-item {
    grid-template-columns: 225px 1fr;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
  .photo {
    height: 175px;
  }
}
@media screen and (min-width: 1200px) {
  .section-center {
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo {
    height: 150px;
  }
}
